import React, { Component } from 'react';
import config from "../utils/config.js";
import OpenAI from 'openai';

class ChatDesktop extends Component {
  constructor(props) {
    super(props);
    this.state = {
      message: "",
      userMessages: [{
        role: "assistant", content: "Hey there!"
      },
      {
        role: "assistant", content: "Want to add or replace an ingredient in this recipe? Just ask me!"
      }],
      promptMessages: [],
      loading: false,
      input: "",
      ingredientUpdated: false,
      oldrecipe: this.props.recipe
    }
  }
  componentDidMount() {
    console.log("we think", this.props.recipe);
  }
  async changeRecipe(e) {
    this.setState({
      loading: true,
      input: ""
    })
    let ingredients = []
    this.props.recipe.ingredients.forEach((ingredient) => {
      ingredients.push(ingredient.ingredientOptions[0])
    })
    console.log("final", ingredients)
    const promptTemplate = [];
    // const newIngredients = [];
    promptTemplate.push(...this.state.promptMessages, {
      role: 'assistant', content: `You're a sous chef assistant and always you sound like a professional chef. now your job is to help update user preferred ingredient, diet, cookware and answer questions like calories prep methods in the receipe provided. Only give information on recipe provided, Refrain from answering any other questio unless it's related to recipe such as calories etc
          Follow the steps below
           Step 0 : Always get user confirmation on what ingredient/cookware you're about change before changing/giving final output.
            Step 1 - User Asks a question, if it's about ingredient or cookware or dietery change, go to step 2 or otherwise answer
            Step 2 - If user says he wants to change a ingredient/cookware/dietery preference or don't have it.. based on the recipe name provided check if the ingredient is main ingredient (for example, lemon is main in lemon rice). if main goto step 3 or suggest a new ingredient without any additional texts and ask if you need to proceed
            Step 3 - If it's main ingredient your response should be -  Hey, lemon in lemon rice is a key ingredient. If I change this then it will become a new recipe. Would you want to continue?
            Step: 3.5 Most important step: Always make sure you give proper ingredients that really exists and proper ingrdients that aligns well with the recipe do mnot give fake ingredients like beef brest, chicken riblets etc.. beef tendorlein, chicken thighs like ingredients is only valid.. resaerch the recipe and give that suits.

            Step 4 - If user says yes, then update that ingredient with new one and return the ingredient. Make sure the ingredient change make sense.. like you cannot add sugar in traditional coffee right? and only change the one ingredient after suggesting it unless asked otherwise. For instance ask the user if they want to make changes to dietery preference or just the ingredient if the change requires dietery change unless just one. You can reject and let the user know if the ingredient doen't make sense.            important: When user says yes to change - return ingredient/cookware only as final response with ingredient: or Cookware: and importantly don't ask the user to give a ingredient or cookware, always you suggest those and ask if you can continue with the change
            important: When user says yes to change - return ingredient/cookware only as final response with ingredient: or Cookware: and importantly don't ask the user to give a ingredient or cookware, always you suggest those and ask if you can continue with the change
            go through step by step and make sure you give correct response. always get confirmation before changing the ingredients or cookware
              Remember you don't need to overthink as well. If user asks to remove cheese that doesn't me if need to make the dish dairy free. but then if recipe doesn't makes without it kindly say it and give options

            Example 
            Ingredient: new: 1/2 cup of white onion, old: old_id 
            if cookware
            Example 
            Cookware: new: Ladle, old: spoon 
            and don't add . or anything after old_id
            remember above is only example you're giving answers for specific ingrediet/cookware that user asks for the specific recipe provided
            think, follow steps and answer carefully.
            Read step by step
           ` })
    promptTemplate.push(...this.state.promptMessages, { role: 'user', content: `The recipe Name is ${this.props.recipe.title}, and description is ${this.props.recipe.description},} their calories are Low: ${this.props.recipe.calories.low}, high: ${this.props.recipe.calories.high}}` }) //simple value

    ingredients.forEach((index, ingre) => {
      console.log("ingre are", index)
      promptTemplate.push(...this.state.promptMessages, { role: 'user', content: `The recipe steps are ${ingre}, ${index.name} their unique id is ${index.id}. consider this whole as one step` }) //simple value
    })
    this.props.recipe.steps.forEach((index, ingre) => {
      console.log("steps are", index)
      promptTemplate.push(...this.state.promptMessages, { role: 'user', content: `The recipe making instructions are ${index.text}` }) //simple value
    })
    this.props.recipe.cookware.forEach((index, ingre) => {
      console.log("cookware are", index)
      promptTemplate.push(...this.state.promptMessages, { role: 'user', content: `The recipe making cookware & appliances  are ${index.name} and their type is ${index.type} ` }) //simple value
    })
    if (this.state.userMessages.length > 0) {
      promptTemplate.push(...this.state.promptMessages, ...this.state.userMessages)
    }
    promptTemplate.push(...this.state.promptMessages, { role: 'user', content: `${this.state.message}` }) //simple value
    console.log(promptTemplate)
    this.setState({ userMessages: [...this.state.userMessages, { role: "user", content: this.state.message }] }) //simple value

    try {
      const openai = new OpenAI({
        apiKey: config.OPENAI_KEY,
        dangerouslyAllowBrowser: true// This is the default and can be omitted
      });
      const chatCompletion = await openai.chat.completions.create({
        messages: promptTemplate,
        model: 'gpt-4o',
      });
      console.log("chat completin is", chatCompletion);
      if ((chatCompletion.choices[0].message.content).toLowerCase().includes("ingredient:") || (chatCompletion.choices[0].message.content).toLowerCase().includes("ingredients:") || (chatCompletion.choices[0].message.content).toLowerCase().includes("cookwares:") || (chatCompletion.choices[0].message.content).toLowerCase().includes("cookware:")) {
        this.setState({
          loading: true
        })
        this.setState({ userMessages: [...this.state.userMessages, chatCompletion.choices[0].message] })
        this.setState({ userMessages: [...this.state.userMessages, { role: "assistant", content: "Quality check started... I'll update the ingredients and instructions if this <b>change request passes</b> our food quality test." }] })


        let newrecipe = this.props.recipe;
        let animatevalue;
        // newrecipe.ingredients.forEach(n => {
        //   if (n.ingredientOptions[0].id == oldValue) {
        //     const pattern = /new:\s*([^,]+)/;

        //     // Using match to find the pattern in the string
        //     const match = ingredientString.match(pattern);

        //     if (match) {
        //       const newValue = match[1].trim(); // Remove any leading/trailing whitespaces
        //       console.log(newValue);
        //       n.ingredientOptions[0].id = newValue;
        //       n.ingredientOptions[0].name = newValue;
        //       animatevalue = newValue
        //     } else {
        //       console.log("No match found.");
        //     }


        //   }
        //   console.log("new", newrecipe)
        // })
        promptTemplate.push(...this.state.promptMessages, {
          role: 'user', content: `Hi, Your job is generate JSON response based on the suggestions you provided earlier ingredient, dietery change or cookware respectively. don't change anything by yourself

          Based on the ingredient/ diet changed, change calories, title, description pls.. but don't replace a new ingredient other than you suggested
for instance if chicken thighs is what recommended for Geera Pork Riblets instead of pork ribs.. change the title to geera chicken thighs... and add proper descriptions ingrdients 
think and execute step by step
 Most important step: Always make sure you give proper ingredients that really exists and proper ingrdients that aligns well with the recipe do mnot give fake ingredients like beef brest, chicken riblets etc.. beef tendorlein, chicken thighs like ingredients is only valid.. resaerch the recipe and give that suits.

       Follow exact structure:
         {
          title: "fresh lemonade",
          description: "Indulge in the tangy and refreshing taste of Fresh Lemonade made with freshly squeezed lemon juice, superfine sugar, and crushed ice. This classic and easy-to-make beverage is perfect for a hot summer day or any time you need a cool and revitalizing drink."
          duration is in seconds
          ingredients: [
            {
              text: "4 to 5 lemons",
              updated: false
            },
            {
              text: "2 cups of water",
              updated: false
              
            },
            {
              text: "Sugar 4 to 5 spoonful",
              updated: true
              
            }
          ],
          steps:[
            {
              text: "taken lemon and cut them",
              instruction: "slice lemon and carefully squeeze the juice and add water. blend and serve ",
              duration: "120"
            },
            {
              text: "Add sugar to taste",
              instruction: "If you prefer sugar add as much as you like",
              duration: "300" 
            }
          ],
          cookware: [
            {
                "originalText": "Toaster",
                "step": 0,
                "quantity": 1,
                "size": "Medium",
                "notes": "Toaster",
                "requiredModes": [],
                "id": "toaster",
                "name": "Toaster",
                "type": "cookware"
            },
            {
                "originalText": "Microwave",
                "step": 1,
                "quantity": 1,
                "size": "Medium",
                "notes": "Microwave",
                "requiredModes": [],
                "id": "microwave",
                "name": "Microwave",
                "type": "appliance"
            },
            {
                "originalText": "Bowl",
                "step": 2,
                "quantity": 1,
                "size": "Medium",
                "notes": "Bowl",
                "requiredModes": [],
                "id": "bowl",
                "name": "Bowl",
                "type": "cookware"
            }
        ]
        }        ` })
        const openaiNew = new OpenAI({
          apiKey: config.OPENAI_KEY,
          dangerouslyAllowBrowser: true// This is the default and can be omitted
        });
        const chatCompletionnew = await openaiNew.chat.completions.create({
          messages: promptTemplate,
          model: 'gpt-4o',
          response_format: { type: 'json_object' }
        });
        let newrecipeJson = JSON.parse(chatCompletionnew.choices[0].message.content);
        let newStepsArray = newrecipeJson.steps
        console.log("newStepsArray", newStepsArray)
        newStepsArray = newStepsArray.map((step, index) => {
          return {
            "number": Number(index) + 1,
            "text": `${step.text}`,
            "annotatedText": `${step.instruction}`,
            "duration": `${step.duration}`,
            "summary": `${step.text}`,
            "widgets": []
          }
        })
        newrecipe.steps = newStepsArray;
        newrecipe.title = newrecipeJson.title;
        newrecipe.description = newrecipeJson.description;
        newrecipe.cookware = newrecipeJson.cookware;

        const transformedResponse = newrecipeJson.ingredients?.map((item, index) => {
          return {
            order: index + 1,
            originalText: "model",
            ingredientOptions: [
              {
                ingredient: "",
                quantity: [],
                measurement: {
                  unit: "unknown"
                },
                id: item.text,
                name: item.text,
                updated: item.updated
              }
            ]
          };
        });
        newrecipe.ingredients = transformedResponse;
        console.log(transformedResponse, "transformedResponse", newrecipe)
        // const checkScore = await fetch(`${config.AI_BASE_URL}/backend/validate_recipe`, {
        //   method: "POST",
        //   headers: {
        //     "Content-Type": "application/json"
        //   },
        //   body: JSON.stringify({
        //     "key": config.AI_API_KEY,
        //     "original_recipe": {
        //       "title": this.props.recipe.title,
        //       "description": this.props.recipe.description,
        //       "steps_list": this.props.recipe.steps,
        //     },
        //     "personalized_recipe": {
        //       "steps_list": newStepsArray,
        //       "title": newrecipe.title,
        //       "description": newrecipe.description
        //     },
        //     "preferences": {
        //     }
        //   })
        // })
        // const checkScoreJson = await checkScore.json()
        // console.log("checkScoreJson", checkScoreJson)
        this.props.updateNewReceipe(newrecipe, animatevalue)
        this.setState({
          loading: false
        })

        this.setState({ userMessages: [...this.state.userMessages, { role: "assistant", content: "Done!" }
        ] })



      } else {
        this.setState({ userMessages: [...this.state.userMessages, chatCompletion.choices[0].message] }) //simple value
      }
      this.setState({ message: '' });
      this.setState({
        loading: false
      })
    } catch (error) {
      console.log('Error processing your request', error);
    }


  }
  render() {
    const messages = [
      { text: "Hey there! How are you doing?", time: "10:00 AM", sender: "user" },
      { text: "I'm good, thank you! How about you?", time: "10:05 AM", sender: "bot" }
    ];
    this.changeRecipe = this.changeRecipe.bind(this)
    return (
      <div className="max-w-lg mx-auto bg-white" style={{
        position: "sticky",
        top: "10%",
        boxShadow: "0 4px 6px rgba(0,0,0,0.1)",
        overflowY: "none",
        height: "100vh",
        width: "30vw"
        // backgroundColor: "white"
      }}>
        <div className="flex flex-col bg-white rounded-3xl">
          <div className="p-6 rounded-t-2xl text-white font-bold text-center text-xl" style={{
            background: "linear-gradient(to right,  #0c5212 0%,  #3ba958 35%)"
          }}>
            Chat with our Sous Chef
          </div>
          <div className="flex-grow overflow-y-scroll" style={{
            height: "70vh",
            marginBottom: "40px"
          }}>
            <div className="flex flex-col space-y-2 p-4">
              {this.state.userMessages.map((message, index) => (
                <div key={index} className={`flex items-center ${message.role === 'user' ? 'self-end' : 'self-start'}`}>

                  <div className={`rounded-[10px] px-4 py-2 ${message.role === 'user' ? 'bg-green-600 text-white rounded-br-none' : 'bg-gray-300 rounded-bl-none'}`}
                    style={{
                      boxShadow: "0 2px 2px rgba(0,0,0,0.2)",
                      wordWrap: "break-word",
                      overflowWrap: "break-word"
                    }}>

                    <p style={{ wordWrap: "break-word", maxWidth: "330px", overflowWrap: "break-word" }} dangerouslySetInnerHTML={{ __html: message.content }}>
                    </p>
                  </div>
                </div>
              ))}
              {this.state.loading && (
                <div className="mt-6">
                  <div class="typing">
                    <span class="circle scaling"></span>
                    <span class="circle  scaling"></span>
                    <span class="circle scaling"></span>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div
            className="flex items-center p-3 bg-white sticky bottom-0 shadow-md"
            style={{
              maxHeight: '60px',
                            boxShadow: 'inset 0 1px 3px rgba(0,0,0,0.1)' // Subtle shadow inside the textarea

            }}
          >
            {this.state.loading ? <div className="flex-grow outline-none opacity-55 text-gray-400" style={{
              border: 'none',
              resize: 'none',
              outline: 'none',
              width: '100%', // Ensure full width within the container
              minHeight: '70px', // Minimum height to accommodate text
              // boxShadow: 'inset 0 1px 3px rgba(0,0,0,0.1)' // Subtle shadow inside the textarea
            }}>Processing...</div> : <textarea
              type="text"
              id='input-field'
              autoFocus={true}
              value={this.state.input}
              placeholder='Type your message...'
              wrap='soft'
              style={{
                border: 'none',
                resize: 'none',
                outline: 'none',
                margin: 0,
                padding: '1rem',
               // Rounded corners
              }}

              onChange={(e) => this.setState({ message: e.target.value, input: e.target.value })}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();  // Prevent the default action
                  if (e.target.value == "") return
                  this.changeRecipe(e);
                }
              }}
              className="flex-grow outline-none"
            />}
            <button
              disabled={this.state.loading || this.state.input == ""}
              className="ml-4 bg-green-600 text-white rounded-full p-2"
              style={{
                width: "35px",
                height: "35px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
              }}
              onClick={this.changeRecipe}
            >
              <svg viewBox="0 0 24 24" fill="none" stroke="currentColor" className="w-6 h-6">
                <path d="M5 12h14M12 5l7 7-7 7" />
              </svg>
            </button>
          </div>
        </div>
      </div>
    );
  }
}


export default ChatDesktop;