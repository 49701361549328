import React, { Component } from 'react';
import LoadingIcons from 'react-loading-icons'
import pluralize from 'pluralize';

import SmartRecipeService from '../services/smartrecipe';
import ContentMetadataService from '../services/contentmetadata';
import RecipeInteractionService from '../services/recipeinteraction.js';
import * as Dialog from '@radix-ui/react-dialog';

import PersonalizationDropdown from './PersonalizationDropdown';
import ApplianceControlWidget from './ApplianceControlWidget';
import TagPill from './TagPill.js';
import ConsumerChat from './ConsumerChat.js'
import { capitalizeWord } from '../utils/capitalizeWord';
import convertParagraphToSentenceArray from '../utils/converParagraphToSentenceArray';
import getPrettyQuantity from '../utils/getPrettyQuantity';
import getPluralMeasurement from '../utils/getPluralMeasurement';
import minsToHours from '../utils/minsToHours';
import getPrettyDuration from '../utils/getPrettyDuration.js';
import config from '../utils/config.js';


import { Player } from '@lottiefiles/react-lottie-player';
import loader from '../loader/loading_animation.json'
import loader1 from '../loader/main_loading_animation.json'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBowlFood, faCalculator, faLanguage, faMagicWandSparkles, faEarth, faCircle, faTemperatureHalf, faRuler, faArrowRight, faRefresh, faUtensils } from '@fortawesome/free-solid-svg-icons'
import { faLightbulb } from '@fortawesome/free-regular-svg-icons';
import { faInstagram, faTiktok, faYoutube } from '@fortawesome/free-brands-svg-icons';

import {
    languageOptions, dietOptions, servingOptions, applianceModes, temperatureUnitOptions, measurementUnitOptions,
    languageOptionsObj, dietOptionsObj, servingOptionsObj, applianceModesObj, temperatureUnitOptionsObj, measurementUnitOptionsObj,
    useAppliancesOption
} from '../utils/personalizationOptions';
import OpenAI from 'openai';

//
import '../css/ConsumerRecipeCard.css';
import "../css/components/ApplianceControlWidget.css";
import RecipeService from '../services/recipe.js'
import toast from 'react-hot-toast';
import { X } from 'lucide-react';
import clsx from 'clsx';
import NewPersonalizationDropdown from './NewPersonalizationDropdown.js';
import ChatDesktop from './ConsumerChatDesktop.js';


//

const placeholderImageUrl = "/empty-image.png";

//

class ConsumerRecipeCardDS extends Component {

    toggleChat = () => {
        this.setState(prevState => ({
            showChat: !prevState.showChat
        }));
    }


    constructor(props) {

        super(props);

        this.state = {
            originalRecipe_id: this.props.recipe_id,
            recipe_id: undefined,
            recipe: undefined,
            reprocess: false,
            aiTemperature: 0.66,
            personalizationOptions: {},
            curPageNumber: 0,
            isLoading: true,
            animateName: "no animate",
            buttonClicked: false,
            myAppliances: "",
            showChat: true,
            openDropdownId: null,
            feedback: ""
        };

        this.lastActivityTime = Date.now()

        this.updatePersonalizationOptions = this.updatePersonalizationOptions.bind(this);
        this.personalizeRecipeRequest = this.personalizeRecipeRequest.bind(this);
        this.handleUserActivity = this.handleUserActivity.bind(this);
        this.addInteraction = this.addInteraction.bind(this);
        this.updateNewReceipe = this.updateNewReceipe.bind(this)
        // this.updateLoading = this.updateLoading.bind(this)

    }

    async componentDidMount() {
        if (window.innerWidth < 1024) {
            this.setState({
                showChat: false
            })
        }
        console.log("props are", this.props)
        // Event listeners for user activity
        window.addEventListener('mousemove', this.handleUserActivity);
        window.addEventListener('keydown', this.handleUserActivity);
        window.addEventListener('scroll', this.handleUserActivity);

        const queryParams = new URLSearchParams(window.location.search)

        let personalizationOptions = {
            language: queryParams.get("language") ? languageOptionsObj[queryParams.get("language")] : undefined,
            diet: queryParams.get("diet") ? dietOptionsObj[queryParams.get("diet")] : undefined,
            servings: queryParams.get("servings") ? servingOptionsObj[queryParams.get("servings")] : undefined,
            applianceMode: queryParams.get("applianceMode") ? applianceModesObj[queryParams.get("applianceMode")] : undefined,
            temperatureUnits: queryParams.get("temperatureUnits") ? temperatureUnitOptionsObj[queryParams.get("temperatureUnits")] : undefined,
            measurementUnits: queryParams.get("measurementUnits") ? measurementUnitOptionsObj[queryParams.get("measurementUnits")] : undefined,
            includedIngredients: queryParams.get("include") ? queryParams.get("include").split(',') : [],
            excludedIngredients: queryParams.get("exclude") ? queryParams.get("exclude").split(',') : [],
        };

        console.log({ msg: 'componentDidMount', personalizationOptions });

        const contentMetadata = "";

        this.setState({
            contentMetadata,
            reprocess: queryParams.get("reprocess") === true || queryParams.get("reprocess") === "true",
            aiTemperature: queryParams.get("aiTemp") ? parseFloat(queryParams.get("aiTemp")) : this.state.aiTemperature,
            showTags: (queryParams.get("showTags") === false || queryParams.get("showTags") === "false" || queryParams.get("showtags") === false || queryParams.get("showtags") === "false") ? false : true,
            personalizationOptions,
            isLoading: false,
        });

        this.personalizeRecipeRequest(personalizationOptions, true);

    }

    componentWillUnmount() {
        // clean up event listeners
        this.removeWindowListeners();
    }

    //

    removeWindowListeners() {
        window.removeEventListener('mousemove', this.handleUserActivity);
        window.removeEventListener('keydown', this.handleUserActivity);
        window.removeEventListener('scroll', this.handleUserActivity);
    }

    getWindowScrolledPercentage() {
        const scrollPosition = window.scrollY || document.documentElement.scrollTop;
        const visibleHeight = window.innerHeight || document.documentElement.clientHeight;
        const totalHeight = document.documentElement.scrollHeight;
        return parseFloat(((scrollPosition / (totalHeight - visibleHeight)) * 100).toFixed(2));
    }

    handleUserActivity() {
        if (Date.now() - this.lastActivityTime > 60000) {
            this.addInteraction('page', {
                type: 'user-active',
            });
            this.lastActivityTime = Date.now();
        }
    }

    handleOnTagClick = async (tagLabel) => {
        await this.addInteraction('link', { type: 'tag', tagLabel });
        window.open(`/${this.props.type}/recipes?tags=${tagLabel}`);
    }

    //

    async updatePersonalizationOptions(newPersonalizationOptions) {
        console.log({ msg: 'updatePersonalizationOptions', 'oldPersonalizationOptions': this.state.personalizationOptions, newPersonalizationOptions });
        const personalizationOptions = Object.assign(this.state.personalizationOptions, newPersonalizationOptions);
        if (newPersonalizationOptions.appliance) {
            this.setState({
                myAppliances: newPersonalizationOptions.appliance
            })
        } else {
            this.setState({
                myAppliances: this.state.personalizationOptions.appliance
            })
        }
        console.log({ msg: 'updatePersonalizationOptions', personalizationOptions });
        this.setState({ personalizationOptions });
    }

    async personalizeRecipeRequest(personalizationOptions = this.state.personalizationOptions, reprocessOverride = false, buttonclicked = '') {
        if (this.state.myAppliances) {
            this.setState({ isLoading: true, personalizationDuration: undefined });
            console.log("hiamm", this.state.personalizationOptions)
            let selectedcook;
            let cookware_id = this.state.myAppliances.id
            if (this.state.myAppliances.id == "rice-cooker") {
                selectedcook = "KitchenAid 5.3 cu. ft. Grain and Rice Cooker -  Incorporate the use of its integrated scale to precisely measure grains, the water tank to ensure accurate water-to-grain ratios, and relevant preset options for effortless grain and rice cooking. Include instructions on choosing the appropriate preset mode based on the type of grain (e.g., white rice, brown rice, quinoa) for optimal cooking times and textures. Provide guidance on how the cooker automatically adjusts water and cooking time for consistent results."
            } else if (this.state.myAppliances.id == "5.3-u") {
                selectedcook = "Maytag 5.3 cu. ft. Steam Clean Electric Range. Provide recommendations for utilizing the dual-choice heating elements to match cookware sizes for efficient heating, and suggest ways to optimize cooking time using the quick preheat feature for faster meal preparation. Also, include instructions on using the steam clean option post-cooking for eco-friendly, chemical-free cleaning."
            } else {
                selectedcook = `"GE Profile™ 30" Smart Built-In Single Electric Wall Oven. Include guidance on using True European Convection with Direct Air for even high-heat cooking, No Preheat Air Fry for fast, healthier meals, Precision Cooking Modes for precise low-heat tasks, and recommendations for utilizing built-in WiFi and the SmartHQ app. Also, incorporate instructions on using the self-cleaning feature and the temperature probe for perfect cooking results."`
            }

            const responsewithcookware = await fetch(`${config.API_SERVER_URL}/api/v1/recipes/${localStorage.getItem('recipe_id')}/get-recipe`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${this.props.user.token}`
                    
                },
                body: JSON.stringify({
                    cookware_id,
                    personalizationOptions: this.state.personalizationOptions
                })
            });

            const newCookWareData = await responsewithcookware.json();
            console.log('Recipe updated successfully', newCookWareData);
            if (newCookWareData.recipe) {
                this.setState({
                    isLoading: false,
                    recipe: newCookWareData.recipe
                });
               
                
                  return 
            }

            let finalPrompt = `You are a talented and well-trained chef tasked with updating a recipe based on user preferences. Your primary responsibilities include making appropriate changes to the title, description, ingredients, calorie count, appliances, and cooking steps to reflect new cookware, dietary restrictions, serving sizes, and any other user specifications.
Key Instructions:
Recipe to Modify:

Original Recipe: ${JSON.stringify(this.state.recipe)}
New Cookware: ${selectedcook} (Include exact brand names such as KitchenAid, Instant Pot, etc.)
User Preferences: ${JSON.stringify(personalizationOptions)} (Including dietary preferences, serving size changes, and temperature adjustments)
Recipe Updates:

Ingredients: Change ingredients based on dietary preferences. For example, if the user requests a dairy-free option, replace dairy products with specific alternatives like soy milk or almond cheese. Always use real, high-quality substitutes. Avoid vague terms like "dairy-free cheese" or "vegan alternative."
Cookware: Incorporate new cookware into the list of appliances and adjust cooking times accordingly. Make sure to reflect how the new cookware (e.g., Instant Pot, air fryer) improves efficiency or reduces cooking time.
Cooking Steps: Update all cooking steps to reflect the new cookware. Be precise about how the new equipment changes the instructions (e.g., reduced cooking time, different techniques). Specify exact time and temperature changes required by the new equipment.
Servings: Adjust ingredient quantities, cooking times, and instructions based on any changes to the number of servings requested by the user.
Calorie Count: Update the calorie count if ingredients or servings have changed. Ensure accuracy in the calculation based on the new ingredients.
Steps for Cooking Instructions:

Title, description and steps: Ensure proper title. if chicken wings changed to veg.. don't say cauliflower wings because that doesn't makes sense.

Modify all cooking instructions to reflect the new cookware and changes in cooking time. Be detailed and clear about how the new equipment impacts each step (e.g., faster cooking time in an air fryer or pressure cooker).
Include precise duration for each step, making sure the cooking times are updated based on the new cookware.
Ensure the instructions flow logically and are easy to follow based on the updated equipment and ingredients.
Output Format:

The output must follow the exact same JSON structure as the original recipe. No changes should be made to the structure itself, and fields like created_at and author must remain unaltered.
All cookware and appliances should be added to the appropriate section in the JSON without removing any existing appliances, unless explicitly requested.
Remember think multiple times before updating a recipe steps or instruction or ingredient.
Important: remove unused appliances,Brand name in appliance or cookware is most important and make sure to color the brand appliance in description section using tailwind <span class="font-bold text-green-500"> (only color and bold the brand appliance name not others texts). and in while adding a key in cookware object add new key called new:true. never miss this don't add html tags anywhere other than in appliance that too for only new brand cookware/applaince we changed
Example for description: "description": "Indulge in the comforting flavors of this Roasted Pear and Apple Sauce, a delightful American dessert. Made with sweet red apples, ripe Bosc pears, and a hint of cinnamon, this gluten-free sauce is cooked to perfection with citrus zest and juice, creating a smooth yet chunky texture. This recipe now utilizes the <span class=\"font-bold text-green-500\">KitchenAid Grain and Rice Cooker</span> for precise cooking and consistency. Perfect served warm or at room temperature.",

Example for cookware: 
{
      "originalText": "KitchenAid Grain and Rice Cooker",
      "step": 2,
      "quantity": 1,
      "size": "Large",
      "notes": "KitchenAid Grain and Rice Cooker",
      "id": "rice-cooker",
      "name": "KitchenAid Grain and Rice Cooker",
      "type": "appliance",
      "new": true
    }
    again reminding: follow all steps change color, add new:true.

`
            console.log(finalPrompt)
            const openaiNew = new OpenAI({
                apiKey: config.OPENAI_KEY,
                dangerouslyAllowBrowser: true
            });
            try {
                const chatCompletionnew = await openaiNew.chat.completions.create({
                    messages: [{
                        role: "assistant",
                        content: finalPrompt
                    }],
                    model: 'gpt-4o',
                    response_format: { type: 'json_object' }
                });
                console.log(chatCompletionnew)
                let newrecipeJson = JSON.parse(chatCompletionnew.choices[0].message.content);
                console.log("newrecipeJson is", newrecipeJson)
                
                const insertCookwareRecipe = await fetch(`${config.API_SERVER_URL}/api/v1/recipes/${localStorage.getItem('recipe_id')}/store-recipe`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${this.props.user.token}`

                    },
                    body: JSON.stringify({
                        cookware_id,
                        recipeData:newrecipeJson,
                        personalizationOptions: this.state.personalizationOptions
                    })
                });
    
                const insertNewCookwareRecipe = await insertCookwareRecipe.json();
                console.log('Recipe updated successfully', insertNewCookwareRecipe);
              
                this.setState({
                    isLoading: false,
                    recipe: newrecipeJson
                });
               return
            } catch (e) {
                toast(`Something went wrong while personalizing your recipe, Please try again`, {
                    position: ''
                })
                this.setState({
                    isLoading: false,
                });
                return
            }
            return
        }

        console.log({ msg: 'personalizeRecipeRequest', personalizationOptions });

        const { originalRecipe_id, reprocess, aiTemperature } = this.state;
        const { language, diet, servings, applianceMode, temperatureUnits, measurementUnits, includedIngredients, excludedIngredients } = personalizationOptions;

        this.setState({ isLoading: true, recipe: undefined, personalizationDuration: undefined });
        console.log("localStorage.getItem('recipe_id'),", localStorage.getItem('recipe_id'),)
        if (buttonclicked == "buttonclicked") {
            const responses = await fetch(`${config.AI_BASE_URL}/personalize`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    "key": config.AI_API_KEY,
                    "recipe_id": localStorage.getItem('recipe_id'),
                    "preferences": {
                        "serving_count": personalizationOptions.servings?.id,
                        "temperature": personalizationOptions.temperatureUnits?.id == "c" ? "Celsius" : personalizationOptions.temperatureUnits?.id == "f" ? "Fahrenheit" : "",
                        "diet": personalizationOptions.diet ? [personalizationOptions.diet?.label] : [],
                        "langauge": personalizationOptions.langauge,
                        "language": personalizationOptions.language?.label,
                        "user_id": localStorage.getItem('user_id') ? localStorage.getItem('user_id') : undefined,
                        "use_my_appliances": this.state.myAppliances?.label == "My Appliances" ? true : false,
                        "appliance": this.state.myAppliances?.id !== "my" ? this.state.myAppliances?.id == null ? [] : [this.state.myAppliances?.id] : []
                    }
                })
            })
            const responsesPromise = await responses.json()
            console.log("responsePromise", responsesPromise)
            if (responsesPromise.status == 400) {
                this.setState({
                    isLoading: false
                })
                return this.failedToLoad();
            }
            if (responsesPromise.status == 200 && typeof (responsesPromise.personlized_recipe) == "object") {
                responsesPromise.personlized_recipe.is_copied_to_recipe_schema = false;
                setTimeout(async () => {
                    const getRecipeId = await SmartRecipeService.getNewRecipeid(responsesPromise.personlized_recipe.recipe_id);
                    console.log("getRecipeId", getRecipeId)
                    if (getRecipeId) {
                        console.log("get")
                        const updatePersonalizedrecipe = await fetch(`${config.API_SERVER_URL}/api/v1/update-recipe-to-personalized`, {
                            method: "POST",
                            headers: {
                                'Content-Type': 'application/json',
                                'Authorization': `Bearer ${this.props.user.token}`
                            },
                            body: JSON.stringify({
                                "user_id": this.props.user.email,
                                "id": getRecipeId.copied_recipe_id
                            })
                        });
                        const resolvePromise = await updatePersonalizedrecipe.json();
                        console.log("resolve", resolvePromise)
                        localStorage.setItem("personalized_recipe", JSON.stringify(responsesPromise))
                        // window.location.href = `/consumer/recipes/${getRecipeId.copied_recipe_id}/view`
                        window.location.href = `/consumer/recipes/personalized/view`

                    } else {
                        window.location.href = `/consumer/recipes`
                    }

                }, 3000)
            } else {
                toast(`${responsesPromise.personlized_recipe}`, {
                    position: ''
                })
            }
        }

        // if reprocess query param is set, ignore it on page refresh and only use when Personalize button is pressed
        const reprocessResult = (reprocessOverride === true) ? false : reprocess;

        const personalizationObj = {
            recipe_id: originalRecipe_id,
            reprocess: reprocessResult,
            aiTemperature,
            personalizationOptions: {
                language: language ? language.id : undefined,
                diet: diet ? diet.id : undefined,
                servings: servings ? servings.id : undefined,
                applianceMode,
                units: {
                    temperature: temperatureUnits ? temperatureUnits.id : undefined,
                    measurement: measurementUnits ? measurementUnits.id : undefined,
                },
                includedIngredients,
                excludedIngredients,
            },
        };

        console.log({ msg: 'personalizeRecipeRequest', applianceMode, personalizationObj });

        const personalizationStartTime = new Date();
        const smartrecipe = null;
        const personalizationDuration = (new Date() - personalizationStartTime) / 1000;

        if (smartrecipe) {

            document.title = `Ladle - Smart Recipe - ${smartrecipe.updatedTitle || smartrecipe.title}`;
            console.log({ msg: 'personalizeRecipeRequest', personalizationDuration, smartrecipe });
            const delay = reprocessOverride ? 0 : 3500; // only do delay when clicking personalize button and not on page load

            setTimeout(() => {
                this.setState({ isLoading: false, recipe: smartrecipe, recipe_id: smartrecipe._id, personalizationDuration })
                this.addInteraction('personalization-request', {}, smartrecipe._id);
            }, delay);

        } else {
            let newSmartRecipe = await SmartRecipeService.getNewRecipe(this.props.recipe_id);
            if (newSmartRecipe.error != undefined) {
                newSmartRecipe = await SmartRecipeService.getNewRecipe(this.props.recipe_id, 'personalized')
            }
            console.log("this.props.recipe_id", this.props.recipe_id, 'newSmartRecipe', newSmartRecipe)
            const smartrecipeid = await SmartRecipeService.getNewRecipeid(this.props.recipe_id);
            console.log("smart--recipe", smartrecipeid)
            localStorage.setItem('recipe_id', smartrecipeid?.data?._id);
            document.title = `Ladle - Smart Recipe - ${newSmartRecipe.updatedTitle || newSmartRecipe.title}`;
            const delay = reprocessOverride ? 0 : 3500; // only do delay when clicking personalize button and not on page load
            await RecipeService.insertRecentlyViewed(this.props.recipe_id, this.props.user)
            setTimeout(() => {
                this.setState({ isLoading: false, recipe: newSmartRecipe, recipe_id: newSmartRecipe._id, personalizationDuration })
                this.addInteraction('personalization-request', {}, newSmartRecipe._id);
            }, delay);

        }

    }

    async removeIngredient(ingredientToRemove) {

        const { recipe, personalizationOptions } = this.state;

        const index = recipe.ingredients.findIndex(obj => obj === ingredientToRemove)

        if (index !== -1) {
            recipe.ingredients.splice(index, 1);
        }

        let exclude = personalizationOptions.excludedIngredients ? personalizationOptions.excludedIngredients : [];
        exclude.push(ingredientToRemove.name);

        this.updatePersonalizationOptions({
            excludedIngredients: exclude,
        });

    }

    //

    async addInteraction(updateType, data = {}, recipe_id) {
        recipe_id = recipe_id || this.state.recipe_id;
        console.log({ msg: 'addInteraction', 'recipe_id': this.state.recipe_id, 'user': this.props.user, updateType, data });
        data.scrolledPercentage = this.getWindowScrolledPercentage(); // always set window scrolled percentage
        // await RecipeInteractionService.add({_id:recipe_id, updateType, data, }, this.props.user);
    }

    async updateApplianceMode(applianceMode) {
        console.log({ msg: 'updateApplianceMode: ' + applianceMode });
        this.updatePersonalizationOptions({ applianceMode });
        this.personalizeRecipeRequest(this.state.personalizationOptions);
    }

    isYouTubeURL(url) {
        const youtubeRegex = /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.be)\/.+/;
        return youtubeRegex.test(url);
    }

    getVideoContainer(primaryVideoUrl) {

        let content;
        if (primaryVideoUrl) {// && this.isYouTubeURL(primaryVideoUrl)) {
            const youtubeParams = new URLSearchParams(new URL(primaryVideoUrl).search);
            const videoID = youtubeParams.get("v");
            primaryVideoUrl = `https://www.youtube.com/embed/${videoID}`;
            content = <iframe width="560" height="315" title="Recipe Video" src={primaryVideoUrl} frameborder="0" allowfullscreen></iframe>
        } else {
            content = <></>
        }

        return (
            <div className='video-container'>
                {content}
            </div>
        );

    }

    getQuantityString(quantity) {
        if (!quantity || quantity.length === 0) return '';
        if (quantity[0] && quantity[1] && quantity[0] === quantity[1]) return `${getPrettyQuantity(quantity[0])}`;
        if (quantity[0] && quantity[1]) return `${getPrettyQuantity(quantity[0])} - ${getPrettyQuantity(quantity[1])}`;
        if (quantity[0]) return `${getPrettyQuantity(quantity[0])}`;
        console.log({ msg: "Unknown Quantity", quantity });
        return;
    }

    getPrettyName(measurement, quantity, quantityStr, name) {
        name = measurement.unit === "unset" ? name.charAt(0).toUpperCase() + name.slice(1) : name;
        //console.log('getPrettyName > ', measurement, quantity, name)
        let singleQuantity = (quantity && Array.isArray(quantity) && quantity[0] === quantity[1]) ? quantity[0] : undefined;
        let rangeQuantity = (quantity && Array.isArray(quantity) && quantity[0] !== quantity[1]) ? true : false;
        if ((measurement.unit === "unset" && (singleQuantity && singleQuantity !== 1)) || rangeQuantity) {
            return pluralize(name, singleQuantity, false);
        } else {
            return name;
        }
    }

    getIngredientList(ingredients) {

        //console.log({msg:'getIngredientList', ingredients});

        const ingredientList = ingredients.map((ingredient, index) => {

            //console.log({msg:'getIngredientList', ingredient});

            const originalText = ingredient.originalText;
            console.log("originalText is", ingredient);
            const ingredientsHtml = ingredient.ingredientOptions.map((ingredientOption, index) => {
                //console.log({msg:'getIngredientList', ingredientOption});

                let { name, id, ingredient: ingredient_id, measurement, updated = false, notes = '', preparation = '', size = '', type = '', quantity } = ingredientOption;

                //TODO: This is masking unknown unit names, should remove when debugging
                // let measurementStr = measurement.unit ? ' ' + getPluralMeasurement(measurement, quantity) + ' ' : ' ';
                // measurementStr = measurementStr.indexOf("unknown") > 0 ? "" : measurementStr;

                //console.log({msg:'measurementStr', measurementStr});
                let quantityStr = this.getQuantityString(quantity) || '';
                let nameStr = this.getPrettyName(measurement, quantity, quantityStr, name);
                let notesStr = notes ? ' (' + notes.replace(/^\(|\)$/g, '') + ')' : ''; // remove existing ( ), [ ], { } at start/end of string then surround by ( )
                let prefixStr = ` ${size} ${type}`;
                let preparationStr = preparation ? `, ${preparation}` : '';

                let html = (
                    <span key={`ingredient-${ingredient_id}-${index}`} id={ingredient_id ? `ingredients/${ingredient_id}` : null} className='ingredient-target text-[10pt] lg:text-lg xl:text-xl' style={{
                        color: updated ? "green" : ""
                    }}>
                        <span style={{ color: '#333' }}>{quantityStr}</span>
                        {/* <span style={{ color: '#666' }}>{prefixStr} <a className='ingredient-name' href={`../../ingredients/${ingredient_id}`} onClick={() => this.addInteraction('link-ingredient', {ingredient_id}) }>{nameStr}</a>{preparationStr}</span> */}
                        <span style={{ color: '#666' }}>{prefixStr} <span className='ingredient-name' style={{
                            color: updated == true ? "green" : "",
                            fontWeight: updated == true ? 'bold' : ""
                        }} onClick={() => this.addInteraction('link-ingredient', { ingredient_id })}>{nameStr} </span>{preparationStr}</span>
                        <span style={{ color: '#999' }}>{notesStr}</span>
                        {index < ingredient.ingredientOptions.length - 1 && <span> or </span>}
                    </span>
                );
                return html;
            });


            return (
                <li key={`ingredientListItem${index}`} title={originalText} className={
                    this.state.animateName != "no animate" ? "animate__animated animate__backInUp animate__delay-2s" : ""
                }>
                    <input disabled type="checkbox" id={`checkbox${index}`} />
                    <label htmlFor={`checkbox${index}`}>
                        {ingredientsHtml}
                    </label>
                    {/* <span><FontAwesomeIcon className='icon' icon={faCircleXmark} onClick={() => this.removeIngredient(ingredient)} /></span> */}
                </li>
            );

        });

        return ingredientList;

    }

    getCookwareHtml(cookwareItem, index) {

        const { _id, originalText, name, id, type, notes, size, quantity } = cookwareItem;
        const quantityStr = quantity && quantity > 1 ? quantity : undefined;
        const prefixStr = (quantityStr && size) ? size : (size ? capitalizeWord(size) : undefined);
        const nameStr = prefixStr ? name : capitalizeWord(name);
        const notesStr = notes ? ` (${notes})` : '';

        return (
            <li key={`cookwareListItem${index}`} title={originalText}>
                <input disabled type="checkbox" id={`checkbox${index}`} />
                <label htmlFor={`checkbox${index}`}>
                    <span id={`${type}/${id}`} className={`${type}-target text-[10pt] lg:text-lg xl:text-xl`}>
                        <span style={{ color: '#333' }}>{quantityStr || ''} </span>
                        <span style={{ color: '#666' }}><a className={cookwareItem.new ? "font-bold text-green-500": ""} dangerouslySetInnerHTML={{__html:`${nameStr}`}}></a></span>
                        {/* <span style={{ color: '#999' }}>{notesStr}</span> */}
                    </span>
                </label>
            </li>
        );

    }

    getApplianceHtml(applianceItem, index) {

        console.log({ msg: 'getApplianceHtml', applianceItem });

        const { _id, originalText, name, id, type, notes, size, appliance, requiredModes, quantity } = applianceItem;
        const quantityStr = quantity && quantity > 1 ? quantity : undefined;
        const prefixStr = (quantityStr && size) ? size : (size ? capitalizeWord(size) : undefined);
        const nameStr = prefixStr ? appliance.name : capitalizeWord(appliance.name);
        const modesStr = requiredModes.join(', ');
        const notesStr = notes ? ` (${notes})` : '';

        let cookOptionsHtml;
        if (applianceItem.name === "Air-Fryer") {
            cookOptionsHtml = (
                <>
                    <span className='detail-box' style={{ marginLeft: '44px', fontSize: '12pt', padding: '10px' }} onClick={() => this.updateApplianceMode('air-fry')}><FontAwesomeIcon style={{ height: '20px', marginLeft: '4px', marginRight: '12px' }} icon={faLightbulb} /><span>Revert to regular Baking mode</span><FontAwesomeIcon style={{ height: '16px', marginLeft: '12px', marginRight: '8px', paddingTop: '4px', color: '#333' }} icon={faArrowRight} /></span>
                </>
            );
        } else {
            cookOptionsHtml = (
                <>
                    <span className='detail-box' style={{ marginLeft: '44px', fontSize: '12pt', padding: '10px' }} onClick={() => this.updateApplianceMode('air-fry')}><FontAwesomeIcon style={{ height: '20px', marginLeft: '4px', marginRight: '12px' }} icon={faLightbulb} /><span>Try <b>Air-Fry</b> mode in this recipe to save 10 minutes.</span><FontAwesomeIcon style={{ height: '16px', marginLeft: '12px', marginRight: '8px', paddingTop: '4px', color: '#333' }} icon={faArrowRight} /></span>
                    <span className='detail-box' style={{ marginLeft: '44px', fontSize: '12pt', padding: '10px', marginTop: '12px' }}><FontAwesomeIcon style={{ height: '20px', marginLeft: '4px', marginRight: '12px' }} icon={faLightbulb} /><span>See other suggestions</span><FontAwesomeIcon style={{ height: '16px', marginLeft: '12px', marginRight: '8px', paddingTop: '4px', color: '#333' }} icon={faArrowRight} /></span>
                </>
            );
        }

        return (
            <li key={`cookwareListItem${index}`} title={originalText}>
                <span className='label-icon'>
                    <span className="appliance-icon-container">
                        <img className="appliance-icon" src={`/brands/${appliance.brand.toLowerCase()}/appliances/${appliance.model.toLowerCase()}.png`} alt={`${appliance.model}`} />
                    </span>
                    <span id={`${type}/${id}`} className={`${type}-target`}>
                        <span style={{ color: '#333' }}>{quantityStr || ''} </span>
                        <span style={{ color: '#666' }}>{prefixStr || ''} <a style={{ textDecoration: 'underline' }} href={`../../${type}/${id}`} onClick={() => this.addInteraction('link', { type: `${type}section`, id })}>{nameStr}</a><span>&nbsp;&nbsp;•&nbsp;&nbsp;Using {applianceItem.name === "Air-Fryer" ? "Air-Fryer" : "Baking"} Mode</span></span>
                    </span>
                </span>
                <div style={{ marginTop: '12px' }}>
                    {cookOptionsHtml}
                </div>
            </li>
        );

    }

    getApplianceList(cookwareAndAppliances) {
        if (!cookwareAndAppliances || cookwareAndAppliances.length === 0) return <></>;
        const appliances = cookwareAndAppliances.filter(item => item.type === "appliance");
        if (!appliances || appliances.length === 0) return <></>;
        return (
            <div className="mt-3 listSection">
                <a href="#appliances" onClick={() => this.addInteraction('link', { type: `appliancesection` })}><h1 id="appliances" className="text-[14pt] lg:text-[24pt] text-[#0e4621]">Appliances</h1></a>
                <ul>
                    {appliances.map(a => {
                        if (a.appliance) return this.getApplianceHtml(a);
                        return this.getCookwareHtml(a);
                    })}
                </ul>
            </div>
        );
    }

    getCookwareList(cookwareAndAppliances) {

        if (!cookwareAndAppliances || cookwareAndAppliances.length === 0) return <></>;
        let cookware = cookwareAndAppliances.filter(item => item.type === "cookware" || item.type === "supply");
        if (!cookware || cookware.length === 0) return <></>;

        cookware = cookware.sort((a, b) => {
            if (a.type < b.type) return -1;
            if (a.type > b.type) return 1;
            return 0;
        });

        return (
            <div className="mt-3 listSection">
                <a href="#cookware" onClick={() => this.addInteraction('link', { type: `cookwaresection` })}><h1 id="cookware" className="text-[14pt] lg:text-[24pt] text-[#0e4621]">Cookware & Supplies</h1></a>
                <ul className="w-56 md:w-[100%] text-[10pt] lg:text-lg xl:text-xl">
                    {cookware.map((cookwareItem, index) => {
                        return this.getCookwareHtml(cookwareItem, index);
                    })}
                </ul>
            </div>
        );
    }

    //

    createRecipeHeader(recipe, contentMetadata, personalizationDuration) {

        let { title, updatedTitle, author, source, media, tags, times: cooktime, servings: recipeServings, cuisines, calories, ingredients, createdAt, updatedAt } = recipe;
        const { contentUrl = '//' + source?.url, socialUrls = undefined } = contentMetadata;


        //
        const heroImageUrl = (media && media.heroImageUrl) ? media.heroImageUrl : placeholderImageUrl;
        const primaryVideoUrl = socialUrls && socialUrls[0] ? socialUrls[0] : undefined;

        //

        const headerStyleNoVideo = {
            backgroundImage: `linear-gradient(to top, rgba(255, 255, 255, 0.95) 0%, rgba(255, 255, 255, 0.55) 25%, rgba(255, 255, 255, 0.10) 100%), url('${heroImageUrl}')`,
        };

        const headerStyleWithVideo = {
            backgroundImage: `linear-gradient(to top, rgba(255, 255, 255, 0.95) 0%, rgba(255, 255, 255, 0.9) 25%, rgba(255, 255, 255, 0.6) 100%), url('${heroImageUrl}')`
        };

        const headerStyleWithVideoMobile = {
            backgroundImage: `url('${heroImageUrl}')`
        };

        const socialDivider = (
            <span style={{ marginLeft: '6px', marginRight: '6px' }}></span>
        );
        let url = contentUrl;

        var index = url.indexOf('%');
        if (index !== -1) {
            url = url.substring(0, index);
        }
        const socialIcons = (
            <>
                {config.DEMO_MODE ? <FontAwesomeIcon className="social-icon" icon={faYoutube} /> : <></>}
                {config.DEMO_MODE ? <FontAwesomeIcon className="social-icon" icon={faTiktok} /> : <></>}
                {config.DEMO_MODE ? <FontAwesomeIcon className="social-icon" icon={faInstagram} /> : <></>}

                <a target='_blank' href={url} onClick={() => this.addInteraction('link', { type: 'social', contentUrl })}><FontAwesomeIcon className="social-icon" icon={faEarth} /></a>
            </>
        );

        //


        // create tags
        const tagPillsHtml = (tags !== undefined && tags.length > 0) ? this.getTagPills(tags, 8) : undefined;
        const tagsContainer = (tags !== undefined && tags.length > 0) ? (<div className="py-5 flex flex-row flex-wrap gap-1">{tagPillsHtml}</div>) : <></>;

        // generate cook/prep/total times + tooltip
        const prepTime = cooktime && cooktime.prepTime ? minsToHours(cooktime.prepTime) : '-';
        const cookTime = cooktime && cooktime.cookTime ? minsToHours(cooktime.cookTime) : '-';
        const totalTime = cooktime && cooktime.totalTime ? minsToHours(cooktime.totalTime) : '-';
        const timeTip = `Prep: ${prepTime}\r\nCook: ${cookTime}\r\nTotal: ${totalTime}`;

        // generate calories data
        const calLow = calories && calories.low;
        const calHigh = calories && calories.high;
        const caloriesSingle = (calLow && calHigh && calLow === calHigh) ? calLow : undefined;
        const caloriesString = caloriesSingle || ((calLow && calHigh) ? `${calLow} - ${calHigh}` : 'Not Set');

        //

        const authorRowStyle = { paddingTop: '2px', paddingBottom: '24px', paddingRight: '28px', alignItems: 'center', fontSize: '16pt' };

        const options = {
            weekday: 'short',
            month: 'short',
            day: 'numeric',
            year: 'numeric',
            //hour: 'numeric',
            //minute: 'numeric',
            //hour12: true,
        };

        // format last updated date
        const updatedAtStr = new Intl.DateTimeFormat('en-US', options).format(new Date(updatedAt));
        const durationStr = (!config.DEMO_MODE && personalizationDuration) ? ` @ ${personalizationDuration.toString()}s` : '';

        // find & format first cuisine in the cuisines array if present
        let firstCuisine = cuisines && cuisines[0] ? cuisines[0] : undefined;
        firstCuisine = firstCuisine && firstCuisine.split(',')[0] ? firstCuisine.split(',')[0].trim() : firstCuisine ? firstCuisine.trim() : "";
        const cuisineStr = firstCuisine ? capitalizeWord(firstCuisine) : "- ";

        return (
            <>
                <div className='lg:hidden'>
                    <div className="consumerrecipecard-title break-words text-xl text-[#0e4621] font-bold">{updatedTitle ? updatedTitle : title}</div>
                    <div key="consumerrecipecard-header" className="p-8 consumerrecipecard-header mt-3 lg:mt-0 w-full h-80 lg:h-auto" style={headerStyleWithVideoMobile}></div>
                    {/* <span className='text-lg'>{updatedAtStr}{durationStr} {socialIcons}</span> */}
                </div>
                <div className="hidden lg:block">
                    <div key="consumerrecipecard-header" className="p-8 consumerrecipecard-header mt-3 lg:mt-0 w-full h-80 lg:h-auto" style={primaryVideoUrl ? headerStyleWithVideo : headerStyleNoVideo}>

                        {this.getVideoContainer(primaryVideoUrl)}

                        <div className="consumerrecipecard-info">
                            <div className="consumerrecipecard-title break-words text-[34px] lg:text-[44px] text-[#333] font-medium hidden lg:block">{updatedTitle ? updatedTitle : title}</div>
                            <p className="consumerrecipecard-tagline mb-[24px]">{updatedTitle ? 'Personalized recipe based on ' + title : ''}</p>

                            {/*<div style={{ paddingTop: '24px', paddingBottom: '24px', paddingRight: '28px', display: 'inline-flex', alignItems: 'center' }}><FontAwesomeIcon className="consumerrecipecard-creator-image" icon={faLink} size='1x' /><Link to={contentUrl}>{contentUrl}</Link></div>*/}
                            <div className="hidden lg:block">
                                {tagsContainer}
                            </div>
                        </div>
                        <div className="hidden lg:block w-full">
                            <div className="flex flex-wrap xl:flex-nowrap items-center justify-center w-full mx-auto  bg-opacity-60 bg-white border border-gray-300 rounded-lg overflow-hidden my-2">
                                <div title={timeTip} className="text-center sm:text-left w-full sm:w-1/2 md:w-[15%] lg:w-[13%] xl:w-[30%] p-2 sm:p-0 lg:flex flex-col items-center">
                                    <h4 className="text-[#0e4621] uppercase text-xs sm:text-sm lg:text-base text-center xl:w-32">Total Time</h4>
                                    <p className="text-lg sm:text-xl lg:text-2xl text-center">{totalTime}</p>
                                </div>
                                <div className="hidden sm:block border-r border-gray-300 h-16 sm:h-24 lg:h-32 mx-2 sm:mx-4 lg:mx-6"></div>
                                <div className="text-center sm:text-left w-full sm:w-1/2 md:w-[15%] lg:w-[13%] xl:w-[30%] p-2 sm:p-0 lg:flex flex-col items-center">
                                    <h4 className="text-[#0e4621] uppercase text-xs sm:text-sm lg:text-base text-center">Ingredients</h4>
                                    <p className="text-lg sm:text-xl lg:text-2xl text-center">{ingredients.length}</p>
                                </div>
                                <div className="hidden sm:block border-r border-gray-300 h-16 sm:h-24 lg:h-32 mx-2 sm:mx-4 lg:mx-6"></div>
                                <div className="text-center sm:text-left w-full sm:w-1/2 md:w-[15%] lg:w-[10%] xl:w-[30%] p-2 sm:p-0 lg:flex flex-col items-center">
                                    <h4 className="text-[#0e4621] uppercase text-xs sm:text-sm lg:text-base text-center">Servings</h4>
                                    <p className="text-lg sm:text-xl lg:text-2xl text-center">{recipeServings ? recipeServings : '-'}</p>
                                </div>
                                <div className="hidden sm:block border-r border-gray-300 h-16 sm:h-24 lg:h-32 mx-2 sm:mx-4 lg:mx-6"></div>
                                <div className="text-center sm:text-left w-full sm:w-1/2 md:w-[15%] lg:w-[13%] xl:w-[30%] p-2 sm:p-0 lg:flex flex-col items-center">
                                    <h4 className="text-[#0e4621] uppercase text-xs sm:text-sm lg:text-base text-center">Cuisine</h4>
                                    <p className="text-lg sm:text-xl lg:text-2xl text-center">{cuisineStr}</p>
                                </div>
                                <div className="hidden sm:block border-r border-gray-300 h-16 sm:h-24 lg:h-32 mx-2 sm:mx-4 lg:mx-6"></div>
                                <div className="text-center sm:text-left w-full sm:w-1/2 md:w-[15%] lg:w-[13%] xl:w-[30%] p-2 sm:p-0 lg:flex flex-col items-center">
                                    <h4 className="text-[#0e4621] uppercase text-xs sm:text-sm lg:text-base text-center xl:w-40">Est. Cal / Serving</h4>
                                    <p className="text-lg sm:text-xl lg:text-2xl text-center">{caloriesString}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='lg:hidden'>
                    <div>
                        {tagsContainer}
                    </div>
                    <div className='flex flex-col bg-white'>
                        <div className="grid grid-cols-3 mx-auto bg-opacity-60 gap-2 overflow-hidden mt-2 w-full">
                            <div title={timeTip} className="flex flex-col p-2 border rounded-xl border-[#DAA520]">
                                <h4 className="text-[grey] uppercase text-[11px] italic">Total Time</h4>
                                <p className="text-lg font-bold text-[#0e4621]">{totalTime}</p>
                            </div>
                            <div className="flex flex-col p-2 px-4 border rounded-xl border-[#DAA520]">
                                <h4 className="text-[grey] uppercase text-[11px] italic">Ingredients</h4>
                                <p className="text-lg font-bold text-[#0e4621]">{ingredients.length}</p>
                            </div>
                            <div className="flex flex-col p-2 border rounded-xl border-[#DAA520]">
                                <h4 className="text-[grey] uppercase text-[11px]  italic">Servings</h4>
                                <p className="text-lg font-bold text-[#0e4621]">{recipeServings ? recipeServings : '-'}</p>
                            </div>
                        </div>
                        <div className='grid grid-cols-2 mx-auto bg-opacity-60 gap-2 overflow-hidden mt-2 w-full'>
                            <div className="flex flex-col p-2 px-4 border rounded-xl border-[#DAA520]">
                                <h4 className="text-[grey] uppercase text-[11px]  italic">Cuisine</h4>
                                <p className="text-lg font-bold text-[#0e4621]">{cuisineStr}</p>
                            </div>
                            <div className="flex flex-col p-2 border rounded-xl border-[#DAA520]">
                                <h4 className="text-[grey] uppercase text-[11px]  italic">Est. Cal / Serving</h4>
                                <p className="text-lg font-bold text-[#0e4621]">{caloriesString}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );

    }

    // create personalization notes section if needed
    createPersonalizationNotes(personalizationNotes) {
        if (personalizationNotes) {
            personalizationNotes = personalizationNotes.replace(/\.(\s+)?I\b/g, ". We"); // Replace ".  I" with ". We"
            personalizationNotes = personalizationNotes.replace(/\bI\b/g, "we"); // Replace "I" with ". We"
            personalizationNotes = capitalizeWord(personalizationNotes);
        }
        const personalizationNotesHtml = (<><h1><img src="/icons/ladle-magic-stars-512.png" alt="ladle-ai" className="ladle-stars-icon" />Ladle AI Personalization Notes</h1><div className="consumerrecipecard-description"><p className='text-base lg:text-lg xl:text-xl'>{personalizationNotes}</p></div></>);
        return personalizationNotes ? personalizationNotesHtml : <></>;
    }

    handleDropdownVisibility = (id) => {
        this.setState((prevState) => ({
            openDropdownId: prevState.openDropdownId === id ? null : id,
        }));
    };

    createPersonalizationRow({ temperatureUnitOptions, temperatureUnits, measurementUnitOptions, measurementUnits, servingOptions, servings, dietOptions, diet, languageOptions, language, useappliance }) {
        const personalizationReady = !(measurementUnits || temperatureUnits || servings || diet || language || useappliance);
        console.log({ msg: 'createPersonalizationRow', measurementUnits, temperatureUnits, servings, diet, language, personalizationReady });
        const { openDropdownId } = this.state;
        return (
            <>
                <div className='relative hidden lg:block'>
                    <div key={`personalization-options-row`} className={clsx(["consumerrecipecard-options z-10 w-full flex items-center gap-5 transition-translate duration-500 px-8 py-4 bg-white bg-opacity-60 border border-gray-300"])}>
                        <div className='flex flex-col w-1/6'>
                            <div>
                                <img src="/icons/ladle-magic-icon-2-white-512.png" alt="ladle-ai" className="ladle-magic-icon" />
                            </div>
                            <p className='text-left text-[#0e4621] font-bold'>Adjust with Ladle AI</p>
                        </div>
                        <div className='grid xl:grid-cols-6 lg:grid-cols-4 md:grid-cols:3 gap-5'>
                            <PersonalizationDropdown id="temperatureUnits" listOptions={temperatureUnitOptions} listSelection={temperatureUnits} personalizationId="temperatureUnits" listIcon={faTemperatureHalf} listName="Temp" personalizeRequestFunc={this.updatePersonalizationOptions} isDropdownVisible={openDropdownId === 'temperatureUnits'} handleDropdownVisibility={this.handleDropdownVisibility} />
                            <PersonalizationDropdown id="servings" listOptions={servingOptions} listSelection={servings} personalizationId="servings" listIcon={faCalculator} listName="Servings" personalizeRequestFunc={this.updatePersonalizationOptions} isDropdownVisible={openDropdownId === 'servings'} handleDropdownVisibility={this.handleDropdownVisibility} />
                            <PersonalizationDropdown id="diets" listOptions={dietOptions} listSelection={diet} personalizationId="diet" listIcon={faBowlFood} listName="Diet" personalizeRequestFunc={this.updatePersonalizationOptions} isDropdownVisible={openDropdownId === 'diets'} handleDropdownVisibility={this.handleDropdownVisibility} />
                            <PersonalizationDropdown id="languages" listOptions={languageOptions} listSelection={language} personalizationId="language" listIcon={faLanguage} listName="Language" personalizeRequestFunc={this.updatePersonalizationOptions} isDropdownVisible={openDropdownId === 'languages'} handleDropdownVisibility={this.handleDropdownVisibility} isDisabled={true} />
                            <PersonalizationDropdown id="appliances" listOptions={useAppliancesOption}
                                listSelection={this.state.myAppliances} personalizationId="appliance" listIcon={faUtensils}
                                listName="Appliance" personalizeRequestFunc={this.updatePersonalizationOptions}
                                isDropdownVisible={openDropdownId === 'appliances'} handleDropdownVisibility={this.handleDropdownVisibility}
                            />
                            <button className='set-personalize-button mt-3 md:mt-0 text-[3vw] sm:text-[10pt] ml-2' onClick={() => {
                                this.personalizeRecipeRequest(this.state.personalizationOptions, false, "buttonclicked")
                            }} disabled={personalizationReady}>
                                Personalize <FontAwesomeIcon icon={faMagicWandSparkles} />
                            </button>
                        </div>
                    </div>
                </div>

                <div className='lg:hidden'>
                    <NewPersonalizationDropdown
                        servingOptions={servingOptions}
                        dietOptions={dietOptions}
                        applianceOptions={useAppliancesOption}
                        temperatureUnitOptions={temperatureUnitOptions}
                        personalizeRequestFunc={this.updatePersonalizationOptions}
                        personalizeRecipeRequest={() =>
                            this.personalizeRecipeRequest(
                                this.state.personalizationOptions,
                                false,
                                "buttonclicked"
                            )
                        }
                        personalizationReady={personalizationReady}
                    />
                </div>
            </>
        );
    }

    // define description & personalization notes
    createDescriptionSection(description, personalizationNotes) {
        return (
            <div key={`description-section`} className='mt-4 consumerrecipecard-card lg:px-8'>
                {/* <a href="#description" onClick={() => this.addInteraction('link', { type: 'description-section' }) }><h1 id="description">{description ? `Description` : `Missing Description`}</h1></a>
                <div className="consumerrecipecard-description"><p>{description || 'Missing a description'}</p></div> */}
                {
                    description ? (
                        <>
                            <a onClick={() => this.addInteraction('link', { type: 'description-section' })}><h1 id="description" className="text-[14pt] lg:text-[24pt] text-[#0e4621]">{description ? `Description` : `Missing Description`}</h1></a>
                            <div className="consumerrecipecard-description"><p className='text-[10pt] lg:text-lg xl:text-xl' dangerouslySetInnerHTML={{ __html: description}}></p></div>
                        </>
                    ) : <></>
                }
                {this.createPersonalizationNotes(personalizationNotes)}
            </div>
        );
    }

    // define description & ingredients page
    createRequiredItemsSection(ingredients, cookware, heroImageUrl) {

        const ingredientsHtml = (
            <div className="mt-3 listSection">
                <a href="#ingredients" onClick={() => this.addInteraction('link', { type: 'ingredients-section' })}><h1 id="ingredients" className="text-[14pt] lg:text-[24pt] text-[#0e4621]">Ingredients</h1></a>
                <ul>{this.getIngredientList(ingredients)}</ul>
            </div>
        );

        const cookwareHtml = this.getCookwareList(cookware);
        const applianceHtml = this.getApplianceList(cookware);

        return (
            <div key={`required-items-section`} className='consumerrecipecard-card overflow-hidden lg:px-8'>
                <div className="required-items-container flex items-start">
                    <div className="required-items-content">
                        {ingredientsHtml}
                        {applianceHtml}
                        {cookwareHtml}
                    </div>
                    <div className="required-items-image w-[100%] md:pl-2 hidden lg:block">
                        <img src={heroImageUrl} alt="Primary Recipe" />
                    </div>
                </div>
            </div>
        );
    }

    createStepsSection(_id, steps, showTags) {

        const stepsSectionHeader = (
            <>
                <div className='consumerrecipecard-main-divider'></div>
                {this.getVideoThumbnails(steps, _id)}
                <a href="#directions" onClick={() => this.addInteraction('link', { type: 'directions-section' })}><h1 id="directions" className="text-[14pt] lg:text-[24pt] text-[#0e4621]">Directions</h1></a>
            </>
        );

        const stepsHtml = steps.map((curStep, index) => {

            const itemizedSteps = convertParagraphToSentenceArray(curStep.annotatedText);
            const itemizedStepsHtml = itemizedSteps.map((s, index) => {
                const stepHtml = this.getStepHtml(s, showTags && this.props.type !== "consumer");
                return (
                    <p key={`itemized-step-${index}`} title={curStep.text} className="consumerrecipecard-step-text flex text-[10pt] lg:text-lg xl:text-xl"><FontAwesomeIcon className="icon self-start mt-2 h-[7px] lg:h-[8px] text-[#DAA520]" icon={faCircle} /><span>{stepHtml}</span></p>
                );
            });

            let durationHtml = <></>;
            if (curStep.duration && curStep.duration > 0) {
                if (curStep.duration < 60) curStep.duration = 30; // min duration (TODO: Move this to personalization/rendering API code)
                durationHtml = (
                    <>
                        {/* <span style={{ paddingLeft: '12px', paddingRight: '12px', color: '#999' }}>•</span>
                        <span style={{ color: '#666' }}>{getPrettyDuration(curStep.duration)}</span> */}
                    </>
                )
            }

            const applianceWidgetHtml = this.getApplianceControlWidgets(curStep);

            return (
                <React.Fragment key={`steps-${index}`}>
                    {index !== 0 ? <div className='consumerrecipecard-step-divider'></div> : <></>}
                    <div className={index === 0 ? 'consumerrecipecard-steps-section-step-first' : 'consumerrecipecard-steps-section-step'}>
                        <h2 className='text-[12pt] lg:text-xl xl:text-2xl mb-2'>
                            <span id={`step${curStep.number}`}><a href={`#step${curStep.number}`} onClick={() => this.addInteraction('link', { type: 'step', curStep: curStep.number })}>Step {curStep.number}</a></span>
                            <span style={{ paddingRight: '12px', color: '#999' }}>•</span>
                            <span style={{ color: '#111' }}>{curStep.summary ? curStep.summary : ''}</span>
                            {durationHtml}
                        </h2>
                        {itemizedStepsHtml}
                        {applianceWidgetHtml}
                    </div>
                </React.Fragment>
            );

        });

        return (
            <div key="consumerrecipecard-steps-section" className='consumerrecipecard-steps-section lg:px-8'>
                {stepsSectionHeader}
                {stepsHtml}
            </div>
        );

    }

    //

    getTagPills(tags, count, randomColors = false) {
        return tags.slice(0, count).map((tagLabel, index) =>
            <TagPill key={`${tagLabel}${index}`} label={tagLabel} color='grey' size='medium' onClick={this.handleOnTagClick} />);
    }

    getApplianceControlWidgets(s) {
        if (!s.widgets || s.widgets.length === 0) return <></>;
        const applianceWidgets = s.widgets.filter(widget => widget.type === "appliance");
        return applianceWidgets.map((widget, index) => {
            return <ApplianceControlWidget recipe={this.state.recipe} data={widget.applianceControlData} user={this.props.user} tenant={this.props.tenant} key={`appliance-control-widget-${index}`} />;
        });
    }

    getStepHtml(s, showTags) {

        const getStyleItemStyle = (itemType, showTags) => {
            return `stepItem-${showTags ? 'bubble' : 'link'} ${itemType}`;
        }

        const regex = /\{\{(.*?)\}\}/g;
        const partsArray = s.split(regex).map((part, index, array) => {
            if (index % 2 === 0) {
                return <React.Fragment key={`step-html-${index}`}>{part}</React.Fragment>;
            } else {
                let p = part.split(':');
                if (p[0] === 'ingredient' || p[0] === 'i') return <a key={`link-ingredient-${p[2]}-${index}`} className={getStyleItemStyle('ingredient', showTags)} href={p[2] ? `#ingredients/${p[2]}` : null} onClick={() => this.addInteraction('link', { type: 'steps-ingredient', ingredient: p[2] })}>{`${p[1]}`}</a>;
                if (p[0] === 'cookware' || p[0] === 'c') return <a key={`link-cookware-${p[1]}`} className={getStyleItemStyle('cookware', showTags)} href={p[1] ? `#cookware/${p[1]}` : null} onClick={() => this.addInteraction('link', { type: 'steps-cookware', cookware: p[1] })}>{`${p[1]}`}</a>;
                if (p[0] === 'appliance' || p[0] === 'a') return <a key={`link-appliance-${p[1]}`} className={getStyleItemStyle('appliance', showTags)} href={p[1] ? `#appliance/${p[1]}` : null} onClick={() => this.addInteraction('link', { type: 'steps-appliance', appliance: p[1] })}>{`${p[1]}`}</a>;
                if (p[0] === 'supply' || p[0] === 's') return <a key={`link-supply-${p[1]}`} className={getStyleItemStyle('supply', showTags)} href={p[1] ? `#supply/${p[1]}` : null} onClick={() => this.addInteraction('link', { type: 'steps-supply', supply: p[1] })}>{`${p[1]}`}</a>;
            }
        });

        return partsArray;

    }

    getVideoThumbnails(steps, _id) {

        let videoThumbnails = [];
        steps.forEach((curStep, i) => {
            let videoLink = `/video-thumbnails/${_id}/thumb${i}.png`;
            const thumbnailHtml = (
                <div key={`vid-thumnail-${i}`} className='consumerrecipecard-video-thumbnail'>
                    <img src={videoLink} alt="link to vid" />
                    <span className='consumerrecipecard-video-thumbnail-label'>Step {i + 1}  ({curStep.duration / 60} mins)</span>
                </div>
            );
            if (i < 5) videoThumbnails.push(thumbnailHtml);
        });

        // TODO: REPLACE BELOW WITH REAL CODE
        const recipeHasThumbnails = _id === '646d16c3fdd950029acb7a99' || _id === '646d1702fdd950029acb7b00';
        const videoThumbnailsHtml = recipeHasThumbnails ? videoThumbnails.map(item => item) : <></>;

        return recipeHasThumbnails ? (
            <>
                <h1>Jump to a step in the video</h1>
                <div className='consumerrecipecard-video-container'>
                    {videoThumbnailsHtml}
                </div>
            </>
        ) : <></>;

    }

    reload = () => {
        this.personalizeRecipeRequest({}, false);
    }

    //

    render() {
        const { isLoading, recipe, contentMetadata, personalizationOptions, personalizationDuration, showTags } = this.state;
        console.log({ msg: 'render', isLoading, recipe });
        if (!isLoading && !recipe) return this.failedToLoad();
        if (isLoading && !recipe) return this.renderLoading();
        if (isLoading) return this.renderLoading();

        return this.renderRecipe(this.state.recipe, contentMetadata, personalizationOptions, personalizationDuration, showTags);
    }

    failedToLoad() {

        // something went wrong -- stop recording interactions
        this.removeWindowListeners();

        return (
            <div className="consumerrecipecard w-[100%] sm:w-[500px] md:w-[735px] lg:w-[768px] xl:w-[1024px]">
                <div className="consumerrecipecard-loading-header">
                </div>
                <div className="consumerrecipecard-loading-body">
                    <div style={{ marginBottom: '16px' }}>Something went wrong personalizing your recipe. Please try again!</div>
                    <button className='outline' onClick={() => this.reload()}><FontAwesomeIcon icon={faRefresh} style={{ paddingRight: '12px' }} />Go back</button>
                </div>
            </div>
        )
    }

    renderLoading() {

        let loadingText = "Loading Recipe...";
        let personalizationText = "";

        const { diet, language, servings, applianceMode, includedIngredients, excludedIngredients } = this.state.personalizationOptions;

        console.log('renderLoading');
        console.log({ msg: 'renderLoading', 'this.state.personalizationOptions': this.state.personalizationOptions });

        if (diet || language || servings || applianceMode || (includedIngredients && includedIngredients.length > 0) || (excludedIngredients && excludedIngredients.length > 0)) {
            loadingText = 'Ladle Sous Chef is working its magic'
            personalizationText = applianceMode ? capitalizeWord(applianceMode) : personalizationText;
            personalizationText = (diet && applianceMode) ? personalizationText + ', ' : personalizationText;
            personalizationText = diet ? personalizationText + diet.label : personalizationText;
            personalizationText = (diet && servings) ? personalizationText + ', ' : personalizationText;
            personalizationText = (servings && applianceMode) ? personalizationText + ', ' : personalizationText;
            personalizationText = servings ? personalizationText + servings.label + ' servings' : personalizationText;
            personalizationText = ((diet && language) || (servings && language)) ? personalizationText + ', ' : personalizationText;
            personalizationText = language ? personalizationText + language.label : personalizationText;
        } else if (!this.state.recipe) {
            loadingText = "Loading Recipe";
        }
        return (
            <div className="w-full lg:w-[50%] pt-40 lg:pt-0">
                {loadingText == "Loading Recipe" ? <>
                    <div className="loading-box">
                        <div className="loading-text">
                            <FontAwesomeIcon className="loading-text-animation" icon={faMagicWandSparkles} /> {loadingText}
                        </div>
                        <div className=" w-full flex justify-center min-h-[50px] ">
                            <Player
                                src={loader1}
                                className="player w-[40%]"
                                loop
                                autoplay
                            />
                        </div>
                    </div> </> :


                    <div className="loading-container">
                        <div className="loading-box">
                            <div className="loading-text">
                                <FontAwesomeIcon className="loading-text-animation" icon={faMagicWandSparkles} /> {loadingText}
                            </div>
                            <div className=" w-full flex justify-center min-h-[50px] ">
                                <Player
                                    src={loader}
                                    className="player w-[80%]"
                                    loop
                                    autoplay
                                />
                            </div>
                        </div>
                    </div>
                }
            </div>
        )

    }
    updateNewReceipe(recipe, animatevalue) {
        console.log(recipe)
        this.setState({
            recipe: recipe,
            isLoading: false
        })
        this.setState({
            animateName: animatevalue
        })
    }

    renderRecipe(recipe, contentMetadata, personalizationOptions, personalizationDuration, showTags) {
        let { _id, description, media, personalizationNotes, ingredients, steps, cookware } = recipe;
        const { diet, language, servings, applianceMode, temperatureUnits, measurementUnits, includedIngredients, exludedIngredients } = personalizationOptions;
        const heroImageUrl = (media && media.heroImageUrl) ? media.heroImageUrl : placeholderImageUrl;

        // personalization options
        const options = {
            temperatureUnitOptions, temperatureUnits,
            measurementUnitOptions, measurementUnits,
            servingOptions, servings,
            dietOptions, diet,
            languageOptions, language,
            useappliance: this.state.myAppliances
        };

        const pageSections = [];
        pageSections.push(this.createRecipeHeader(recipe, contentMetadata, personalizationDuration))
        pageSections.push(this.createPersonalizationRow(options))
        pageSections.push(this.createDescriptionSection(description, personalizationNotes));
        pageSections.push(this.createRequiredItemsSection(ingredients, cookware, heroImageUrl));
        pageSections.push(this.createStepsSection(_id, steps, showTags));

        return (
            <div style={{

            }} className='flex md:justify-center lg:mt-[1rem]'>
                
                <div className="lg:max-w-[65%] consumerrecipecard lg:bg-[#fcfcfc] lg:shadow-lg lg:border lg:border-gray-300">
                    {pageSections.map(page => page)}
                    <div className="consumerrecipecard-description">
                        <p className="text-[10pt] p-5 lg:text-sm xl:text-sm text-gray-600 mb-3">
                            *We are making our best estimation for ingredient substitution. However, we are not medical professionals and cannot guarantee any substitutions will be safe for your consumption. Please consult your medical professional for allergy guidance.
                        </p>
                    </div>
                    <div className='mt-4 flex justify-center'>

                        <Dialog.Root>
                            <Dialog.Trigger asChild>
                                <button className="bg-[#0e4621] hover:bg-[#0e4621] text-white font-bold py-2 px-4 rounded-full shadow-lg focus:outline-none focus:ring-2 focus:ring-blue-300 focus:ring-opacity-50">
                                    Submit a Feedback
                                </button>
                            </Dialog.Trigger>
                            <Dialog.Portal>
                                <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-50 transition-opacity" />
                                <Dialog.Content
                                    className="fixed inset-0 z-50 flex items-center justify-center p-4"
                                    style={{ overflowY: "auto" }}
                                >
                                    <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-md mx-auto relative">
                                        <Dialog.Title className="text-2xl font-bold mb-4 text-center text-gray-700">
                                            We'd Love Your Feedback!
                                        </Dialog.Title>
                                        <Dialog.Description asChild>
                                            <form
                                                action="#"
                                                method="POST"
                                                className="space-y-4"
                                                onSubmit={async (e) => {
                                                    e.preventDefault();
                                                    try {
                                                        // Call the addFeedback method and pass the necessary parameters
                                                        const added = await SmartRecipeService.addFeedback(
                                                            this.state.originalRecipe_id,
                                                            this.state.feedback
                                                        );

                                                        // Show success toast
                                                        toast("Your feedback has been recorded! 😀", {
                                                            position: "top-right",
                                                        });

                                                        document.getElementById('close-btn-desk').click();
                                                    } catch (error) {
                                                        console.error("Error submitting feedback:", error);
                                                        toast.error("Failed to submit feedback. Please try again.");
                                                    }
                                                }}
                                            >
                                                <div>
                                                    <label
                                                        htmlFor="feedback"
                                                        className="block text-sm font-medium text-gray-600"
                                                    >
                                                        Feedback
                                                    </label>
                                                    <textarea
                                                        id="feedback"
                                                        name="feedback"
                                                        rows="4"
                                                        onChange={(e) => this.setState({ feedback: e.target.value })}
                                                        placeholder="Your Feedback"
                                                        required={true}
                                                        className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-400"
                                                    ></textarea>
                                                </div>
                                                <div className="flex items-center justify-between">
                                                    <button
                                                        type="submit"
                                                        className="bg-[#0e4621] hover:bg-[#0e4621] text-white font-bold py-2 px-4 rounded-full shadow-lg transition-transform transform hover:scale-105 focus:outline-none focus:ring-2 focus:ring-blue-300 focus:ring-opacity-50"
                                                    >
                                                        Submit
                                                    </button>
                                                </div>
                                            </form>
                                        </Dialog.Description>
                                        <Dialog.Close asChild>
                                            <button
                                                className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
                                                aria-label="Close"
                                                id='close-btn-desk'
                                            >
                                                ✕
                                            </button>
                                        </Dialog.Close>
                                    </div>
                                </Dialog.Content>
                            </Dialog.Portal>
                        </Dialog.Root>

                    </div>

                </div>

                <div className="hidden lg:block lg:max-w-[28%] rounded-t-2xl lg:ml-5 fixed lg:relative bottom-0 left-0 z-[999] lg:z-[0]" style={{
                    // zIndex: "1000" 
                    overflow: 'none'
                }}>
                    {this.state.showChat &&
                        <>

                            <ChatDesktop
                                recipe={recipe}
                                updateNewReceipe={this.updateNewReceipe}
                                setLoading={this.props.setLoading}
                                onClose={this.toggleChat}
                            />
                        </>

                    }
                </div>
                <button
                    className="fixed bottom-4 right-4 bg-[#0e4621] text-white rounded-full p-3 shadow-lg lg:hidden"
                    onClick={this.toggleChat}
                >
                    <img src="/icons/ladle-magic-icon-2-white-512.png" alt="ladle-ai" className="w-7" />
                </button>

                {/* <button
                        className="hidden sm:block fixed bottom-4 right-4 z-10 bg-black text-white rounded-full p-3 shadow-lg"
                        onClick={this.toggleChat}
                    >
                            <BotMessageSquare/>
                    </button> */}

            </div>
            
        )

    }

}

export default ConsumerRecipeCardDS;
