import clsx from "clsx";
import React, { Component, createRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBowlFood,
  faCalculator,
  faMagicWandSparkles,
  faTemperatureHalf,
  faLanguage,
  faSpoon,
  faUtensils,
} from "@fortawesome/free-solid-svg-icons";
import { ChevronDown } from "lucide-react";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../components/ui/tooltip";

class NewPersonalizationDropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 0,
      selectedBoxes: {
        1: null,
        2: null,
        3: null,
        4: null,
      },
      unusedActiveTab: 0,
      unusedSelectedBoxes: {
        5: null,
      },
      isTooltipOpen: false,
    };
    this.dropdownRef = createRef();
  }

  componentDidMount() {
    document.addEventListener("click", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.handleClickOutside);
  }

  handleClickOutside = (event) => {
    if (
      this.dropdownRef.current &&
      !this.dropdownRef.current.contains(event.target)
    ) {
      this.setState({ activeTab: 0 });
    }
  };

  handleTabClick = (tabIndex) => {
    this.setState({ activeTab: tabIndex });
  };

  handleUnusedTabClick = (tabIndex) => {
    this.setState({ unusedActiveTab: tabIndex });
  };

  handleBoxClick = (tabIndex, boxIndex, personalizationId, obj) => {
    this.setState((prevState) => {
      const selectedBoxes = { ...prevState.selectedBoxes };
      selectedBoxes[tabIndex] = obj.id === "reset" ? null : boxIndex;
      const personalizationObj = {};
      personalizationObj[personalizationId] = obj.id === "reset" ? null : obj;
      this.props.personalizeRequestFunc(personalizationObj);
      return { selectedBoxes };
    });
  };

  handleTooltipOpen = () => {
    this.setState({ isTooltipOpen: true });
    setTimeout(() => {
      this.setState({ isTooltipOpen: false });
    }, 3000);
  };

  render() {
    const { activeTab, selectedBoxes, unusedActiveTab, isTooltipOpen } =
      this.state;
    const {
      servingOptions,
      dietOptions,
      temperatureUnitOptions,
      personalizeRecipeRequest,
      personalizationReady,
      applianceOptions,
    } = this.props;

    return (
      <div ref={this.dropdownRef} className="lg:w-[70vw] w-[91vw] mt-4">
        <p className="text-left text-[14pt] lg:text-[24pt] text-[#0e4621] font-bold md:mb-2">
          Cook with Ladle AI
        </p>
        <div className="border-b border-gray-200 text-[12px] md:text-base lg:text-lg mt-2">
          <div className="flex overflow-x-scroll md:overflow-x-auto">
            <button
              className={clsx(
                [
                  "text-gray-600 border border-[#D9D9D9] py-2 px-2 md:px-4 hover:text-white hover:bg-[#DAA520] focus:outline-none rounded-t-lg",
                ],
                [
                  activeTab === 1 || selectedBoxes[1] !== null
                    ? "font-[12px] bg-[#DAA520] text-white"
                    : "bg-white",
                ]
              )}
              onClick={() => this.handleTabClick(1)}
            >
              <span className="flex items-center">
                <FontAwesomeIcon icon={faTemperatureHalf} className="mr-2" />
                {selectedBoxes[1] != null
                  ? temperatureUnitOptions[selectedBoxes[1] - 1].label
                  : "Temp"}
                <ChevronDown />
              </span>
            </button>
            <button
              className={clsx(
                [
                  "text-gray-600 border border-[#D9D9D9] py-2 px-2 md:px-4 hover:text-white hover:bg-[#DAA520] focus:outline-none rounded-t-lg ml-2",
                ],
                [
                  activeTab === 2 || selectedBoxes[2] !== null
                    ? "font-[12px] bg-[#DAA520] text-white"
                    : "bg-white",
                ]
              )}
              onClick={() => this.handleTabClick(2)}
            >
              <span className="flex items-center">
                <FontAwesomeIcon icon={faCalculator} className="mr-2" />
                {selectedBoxes[2] != null
                  ? servingOptions[selectedBoxes[2] - 1].label
                  : "Servings"}
                <ChevronDown />
              </span>
            </button>
            <button
              className={clsx(
                [
                  "text-gray-600 border border-[#D9D9D9] py-2 px-2 md:px-4 hover:text-white hover:bg-[#DAA520] focus:outline-none rounded-t-lg ml-2",
                ],
                [
                  activeTab === 3 || selectedBoxes[3] !== null
                    ? "font-[12px] bg-[#DAA520] text-white"
                    : "bg-white",
                ]
              )}
              onClick={() => this.handleTabClick(3)}
            >
              <span className="flex items-center">
                <FontAwesomeIcon icon={faBowlFood} className="mr-2" />
                {selectedBoxes[3] != null
                  ? dietOptions[selectedBoxes[3] - 1].label
                  : "Diet"}
                <ChevronDown />
              </span>
            </button>
          
            <button
              className={clsx(
                [
                  "text-gray-600 border border-[#D9D9D9] py-2 px-2 md:px-4 hover:text-white hover:bg-[#DAA520] focus:outline-none rounded-t-lg ml-2",
                ],
                [
                  activeTab === 4 || selectedBoxes[4] !== null
                    ? "font-[12px] bg-[#DAA520] text-white"
                    : "bg-white",
                ]
              )}
              onClick={() => this.handleTabClick(4)}
            >
              <span className="flex items-center">
                <FontAwesomeIcon icon={faSpoon} className="mr-2" />
                {selectedBoxes[4] != null
                  ? (applianceOptions[selectedBoxes[4] - 1].label).split(' ').slice(0, 2).join(' ') + '...' 
                  : "Appliance"}
                <ChevronDown />
              </span>
            </button>
          </div>
        </div>
        <div className="bg-white text-[10px] md:text-sm lg:text-base">
          <div
            className={clsx(["flex overflow-x-scroll p-2"], {
              hidden: activeTab !== 1,
            })}
          >
            {temperatureUnitOptions.map((val, index) => {
              return (
                <div
                  key={index}
                  className={clsx(
                    [
                      "flex-none m-2 px-1 py-1 md:px-2 md:py-1 cursor-pointer border-2 border-grey-500 rounded-lg hover:bg-[#63A466]",
                    ],
                    {
                      "bg-[#63A466] border-[#63A466] hover:bg-[#63A466] text-white":
                        selectedBoxes[1] === index + 1,
                    }
                  )}
                  onClick={() =>
                    this.handleBoxClick(1, index + 1, "temperatureUnits", val)
                  }
                >
                  {val.label}
                </div>
              );
            })}
          </div>
          <div
            className={clsx(["flex overflow-x-scroll p-2"], {
              hidden: activeTab !== 2,
            })}
          >
            {servingOptions.map((val, index) => {
              return (
                <div
                  key={index}
                  className={clsx(
                    [
                      "flex-none m-2 px-1 py-1 md:px-2 md:py-1 cursor-pointer border-2 border-grey-500 rounded-lg hover:bg-[#63A466]",
                    ],
                    {
                      "bg-[#63A466] border-[#63A466] hover:bg-[#63A466] text-white":
                        selectedBoxes[2] === index + 1,
                    }
                  )}
                  onClick={() =>
                    this.handleBoxClick(2, index + 1, "servings", val)
                  }
                >
                  {val.label}
                </div>
              );
            })}
          </div>
          <div
            className={clsx(["flex overflow-x-scroll p-2"], {
              hidden: activeTab !== 3,
            })}
          >
            {dietOptions.map((val, index) => {
              return (
                <div
                  key={index}
                  className={clsx(
                    [
                      "flex-none m-2 px-1 py-1 md:px-2 md:py-1 cursor-pointer border-2 border-grey-500 rounded-lg hover:bg-[#63A466]",
                    ],
                    {
                      "bg-[#63A466] border-[#63A466] hover:bg-[#63A466] text-white":
                        selectedBoxes[3] === index + 1,
                    }
                  )}
                  onClick={() => this.handleBoxClick(3, index + 1, "diet", val)}
                >
                  {val.label}
                </div>
              );
            })}
          </div>
          <div
            className={clsx(["flex overflow-x-scroll p-2"], {
              hidden: activeTab !== 4,
            })}
          >
            {applianceOptions.map((val, index) => {
              return (
                <div
                  key={index}
                  className={clsx(
                    [
                      "flex-none m-2 px-1 py-1 md:px-2 md:py-1 cursor-pointer border-2 border-grey-500 rounded-lg hover:bg-[#63A466]",
                    ],
                    {
                      "bg-[#63A466] border-[#63A466] hover:bg-[#63A466] text-white":
                        selectedBoxes[4] === index + 1,
                    }
                  )}
                  onClick={() => this.handleBoxClick(4, index + 1, "appliance", val)}
                >
                  {val.label}
                </div>
              );
            })}
          </div>
        </div>

        <div className="border-b border-gray-200 text-[12px] md:text-base lg:text-lg mt-2">
          <div className="flex">
                  <TooltipProvider>
            <Tooltip open={isTooltipOpen}>
              <TooltipTrigger asChild>
                   
              <button
                    className={clsx(
                      [
                        "text-gray-800 py-2 px-2 md:px-4 hover:text-gray-800 bg-[#D9D9D9] hover:bg-[#c3c3c3] focus:outline-none rounded-t-lg",
                      ],
                      {
                        "font-[12px] bg-[#D9D9D9]": unusedActiveTab === 5,
                      }
                    )}
                    onMouseEnter={this.handleTooltipOpen}
                    onClick={() => this.handleUnusedTabClick(5)}
                  >
                    <span className="flex items-center">
                      <FontAwesomeIcon icon={faLanguage} className="mr-2" />
                      Language
                    </span>
                  </button>
            </TooltipTrigger>
                <TooltipContent side="right" align="center">
                  <p className="text-xs">Coming Soon...</p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </div>
        </div>

        <button
          className="set-personalize-button-mobile flex items-center justify-center mt-4 py-3 px-4 text-base lg:text-lg"
          onClick={personalizeRecipeRequest}
          disabled={personalizationReady}
        >
          Personalize <FontAwesomeIcon icon={faMagicWandSparkles} />
        </button>
      </div>
    );
  }
}

export default NewPersonalizationDropdown;
                